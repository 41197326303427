





























































































































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import { EnumDto } from '@/models/EnumDto';
import { downloadFile } from '@/utils/download';

export default Vue.extend({
  name: 'school-term-view',
  props: {
    schoolTermId: {
      type: String,
      required: false
    }
  },
  data: () => ({
    deleting: false,
    confirmDelete: false as Boolean,
    form: {} as SchoolTermDto,
    selectedTeachers: [],
    educatorOptions: [] as string[],
    schoolTermOptions: [] as string[],
    originalState: undefined as any,
  }),
  async mounted() {
    await this.loadSchoolTermStatusTypes();
    await this.loadSchoolTerms();
    await this.loadSchoolTerm(this.schoolTermId);

    const schoolTermIds = this.schoolTerms.map(s => s.id);
    this.educatorOptions = ["Overfør ikke", ...schoolTermIds];
    this.schoolTermOptions = schoolTermIds;

    this.assignCriteria([
      { key: 'id', validation: () => !!this.form.id, message: "Skal udfyldes" },
      { key: 'id', validation: () => `${this.form.id}`.match(/^[\d]{2}[A-ZÆØÅ]$/), message: "Semester id skal bestå af to tal og slutte med et bogstav, f.eks. 20F" },
      { key: 'year', validation: () => !!this.form.year, message: "Skal udfyldes" },
      { key: 'year', validation: () => new RegExp(/^[\d]{4}$/).test(this.form.year), message: "Året skal bestå af fire tal" },
      { key: 'startWeek', validation: () => !!this.form.startWeek, message: "Skal udfyldes" },
      { key: 'startWeek', validation: () => parseInt(this.form.startWeek) > 0, message: "Værdien skal være større end 0" },
      { key: 'endWeek', validation: () => !!this.form.endWeek, message: "Skal udfyldes" },
      { key: 'endWeek', validation: () => parseInt(this.form.endWeek) > 0, message: "Værdien skal være større end 0" },
      { key: 'weeksTotal', validation: () => !!this.form.weeksTotal, message: "Skal udfyldes" },
      { key: 'weeksTotal', validation: () => parseInt(this.form.weeksTotal) > 0, message: "Værdien skal være større end 0" },
      { key: 'teacingNormHoursHf', validation: () => !!this.form.teacingNormHoursHf, message: "Skal udfyldes" },
      this.criteriaValue('teacingNormHoursHf', () => this.form.teacingNormHoursHf, 0, undefined),
      { key: 'teacingNormHoursOther', validation: () => !!this.form.teacingNormHoursOther, message: "Skal udfyldes" },
      this.criteriaValue('teacingNormHoursOther', () => this.form.teacingNormHoursOther, 0, undefined),
      { key: 'yearStudentsFactorHf', validation: () => !!this.form.yearStudentsFactorHf, message: "Skal udfyldes" },
      this.criteriaValue('yearStudentsFactorHf', () => this.form.yearStudentsFactorHf, 0, undefined),
      { key: 'yearStudentsFactorOther', validation: () => !!this.form.yearStudentsFactorOther, message: "Skal udfyldes" },
      this.criteriaValue('yearStudentsFactorOther', () => this.form.yearStudentsFactorOther, 0, undefined),
      { key: 'state', validation: () => this.stateIsValid, message: 'Der kan kun være ét aktivt semester', active: () => !this.newItem }
    ]);
  },
  methods: {
    async download(id: string) {
      const blob = await this.downloadExcel(id) as Blob;
      const file = new File([blob], id, { type: blob.type });

      downloadFile(file);
    },
    ...mapActions('schoolTermStore', ['createSchoolTerm', 'updateSchoolTerm', 'loadSchoolTerm', 'loadSchoolTerms', 'removeSchoolTerm', 'checkSchoolTermDelete', 'downloadExcel']),
    ...mapActions('validationStore', ['assignCriteria', 'validate']),
    ...mapActions('enumStore', ['loadSchoolTermStatusTypes']),
    async remove(controls) {
      try {
        await this.removeSchoolTerm(this.schoolTermId);
        controls.close();
        this.$router.push({ name: 'SchoolTerms' });
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved slet af semester.',
          type: 'is-danger'
        });
      }
    },
    async create() {
      try {
        if (await this.validate()) {
          await this.createSchoolTerm(this.form);
          this.$router.push({ name: 'SchoolTerms' });
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opret af semester.',
          type: 'is-danger'
        });
      }
    },
    async update() {
      try {
        if (await this.validate()) {
          await this.updateSchoolTerm(this.form);
          this.$router.push({ name: 'SchoolTerms' });
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved ændring af semester.',
          type: 'is-danger'
        });
      }
    }
  },
  computed: {
    ...mapGetters('classSubjectStore', ['classSubjects']),
    ...mapGetters('schoolTermStore', ['schoolTerm', 'schoolTerms', 'schoolTermApiState', 'schoolTermDeleteCheckApiState', 'schoolTermDeleteCheck']),
    ...mapGetters('validationStore', ['isValid', 'validationMessage', 'criteriaValue']),
    ...mapGetters('enumStore', ['schoolTermStatusApiState', 'schoolTermStatusTypes', 'schoolTermValidStatusTypes']),
    loading() {
      return this.schoolTermApiState === APISTATE.LOADING;
    },
    newItem() {
      return !this.schoolTermId;
    },
    stateIsValid() {
      if (this.form.state) {
        const activeSchoolTerm = this.schoolTerms.find((q: SchoolTermDto) => q.state === 'ACTIVE');
        if (activeSchoolTerm) {
          if (this.form.state === "ACTIVE" && this.schoolTermId !== activeSchoolTerm.id) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    tabs() {
      if (this.newItem) {
        return [{ title: 'Stamdata', to: { name: 'NewSchoolTerm' } }];
      }
      return [];
    },
    formState: {
      get() {
        return this.schoolTermStatusTypes.find(q => q.name === this.form.state);
      },
      set(value: EnumDto) {
        this.$set(this.form, "state", value.name);
      }
    },
    deleteChecking() {
      return this.schoolTermDeleteCheckApiState === APISTATE.LOADING;
    },
    deleteAllowed() {
      return this.schoolTermDeleteCheck?.allow === true;
    },
    deleteWarn() {
      return this.schoolTermDeleteCheck?.warn === true;
    },
    deleteIssues() {
      return this.schoolTermDeleteCheck?.issues ?? [];
    }
  },
  watch: {
    'schoolTerm'(newValue) {
      this.form = { ...newValue };
      this.originalState = this.form.state;
    },
    async 'deleting'(newValue) {
      if (newValue) {
        this.confirmDelete = false;
        await this.checkSchoolTermDelete(this.schoolTermId);
      }
    }
  }

});
