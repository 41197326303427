export function downloadFile(file: File) {
  triggerDownload(new Blob([file], { type: file.type }), file.name);
}

function triggerDownload(obj: Blob | MediaSource, filename: string) {
  const a = document.createElement("a");
  a.setAttribute("style", "display:none;");
  document.body.appendChild(a);
  a.download = filename;
  a.href = URL.createObjectURL(obj);
  a.target = "_blank";
  a.click();
  document.body.removeChild(a);
}